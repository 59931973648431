import { StyledFreeShippingProgress, StyledProgress } from './styled';
import TruckIcon from '$icons/delivery.svg';
import { Text } from '~/shared/components';
import { useBasket } from '~/features/basket';
import { useTranslation } from '~/shared/utils';
import { memo } from 'react';

export const FreeShippingProgress = memo(() => {
    const { translate } = useTranslation();
    const { data: basketData } = useBasket();
    const {
        rawFractionToFreeShipping = 0,
        displayAmountBeforeFreeShipping,
        isOnlyGiftCardsInBasket,
        rawShippingPrice,
    } = basketData || {};

    const dontShowFreeShippingProgress =
        rawShippingPrice && displayAmountBeforeFreeShipping === undefined;

    if (isOnlyGiftCardsInBasket || dontShowFreeShippingProgress) {
        return <></>;
    }

    return (
        <StyledFreeShippingProgress>
            {rawFractionToFreeShipping < 1 ? (
                <>
                    <Text variant="bodySm">
                        {translate('basket.neededForFreeShipping', {
                            value: displayAmountBeforeFreeShipping || '',
                        })}
                    </Text>
                    <StyledProgress fraction={rawFractionToFreeShipping} max={100}></StyledProgress>
                </>
            ) : (
                <>
                    <TruckIcon aria-hidden="true" />
                    <Text variant="bodySm">{translate('basket.freeShippingReached')}</Text>
                </>
            )}
        </StyledFreeShippingProgress>
    );
});
