import styled from '@emotion/styled';
import { Progress } from '../../../../shared/components/Progress/Progress';

export const StyledFreeShippingProgress = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spaces[2],
    color: theme.colors.dark,

    svg: {
        width: theme.spaces[4],
        height: theme.spaces[4],
    },
}));

export const StyledProgress = styled(Progress)(({ theme }) => ({
    alignSelf: 'stretch',
    marginTop: theme.spaces[3],
    padding: `0 ${theme.spaces[3]}`,
}));
