import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledMiniBasketContainer = styled.div({
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    [breakpoints.md]: {
        position: 'absolute',
        top: '100%',
        marginLeft: 'auto',
        maxWidth: '460px',
    },
});

export const StyledMiniBasketHeadline = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[3],

    svg: {
        width: theme.spaces[4],
        height: theme.spaces[4],
    },
}));

export const StyledMiniBasketContent = styled.div(({ theme }) => ({
    padding: `${theme.spaces[6]} ${theme.spaces[5]}`,
}));

export const StyledMiniBasketItems = styled.div(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: theme.spaces[6],
}));

export const StyledMiniBasketItem = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[3],
    gridAutoFlow: 'column',
    gridTemplateColumns: '0.2fr 1fr',
    alignItems: 'center',
}));

export const StyledMiniBasketShipping = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[4],
    marginBottom: theme.spaces[5],
    textAlign: 'right',
}));

export const StyledMiniBasketActions = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[3],
    flexDirection: 'column',
}));
