import React, { memo, useMemo, useEffect, useCallback } from 'react';
import { Overlay, Button, OverlayProps, RouterLink, Text } from '~/shared/components';
import { useBasket } from '../../hooks';
import {
    StyledMiniBasketContainer,
    StyledMiniBasketItems,
    StyledMiniBasketContent,
    StyledMiniBasketActions,
    StyledMiniBasketHeadline,
    StyledMiniBasketShipping,
} from './styled';
import Check from '$icons/check.svg';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { MiniBasketItem } from './components/MiniBasketItem';
import { useRouter } from 'next/router';
import { FreeShippingProgress } from '../FreeShippingProgress';
export type MiniBasketProps = {
    triggerComponent: React.ReactNode;
} & Partial<OverlayProps>;

export const MiniBasket = memo(({ triggerComponent, ...overlayProps }: MiniBasketProps) => {
    const {
        data,
        isMiniBasketOpen,
        toggleMiniBasket,
        lastAddedVariantId: lastAddedVariantId,
        totalQuantity,
    } = useBasket();
    const { data: frameData } = useFrame();
    const { translate } = useTranslation();
    const { asPath } = useRouter();
    const staticLinks = frameData?.staticLinks;

    const lastAddedItem = useMemo(() => data?.lines?.find(({ id }) => id === lastAddedVariantId), [
        data,
        lastAddedVariantId,
    ]);

    const closeMiniBasket = useCallback(() => toggleMiniBasket(false), [toggleMiniBasket]);

    useEffect(() => {
        if (isMiniBasketOpen) {
            toggleMiniBasket(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asPath, toggleMiniBasket]);

    return (
        <>
            {triggerComponent}
            <StyledMiniBasketContainer>
                <Overlay
                    open={isMiniBasketOpen}
                    variant="slide"
                    scrollLock={false}
                    backdrop={true}
                    onDismiss={closeMiniBasket}
                    headline={
                        <StyledMiniBasketHeadline>
                            <Check />
                            {translate('minibasket.headline')}
                        </StyledMiniBasketHeadline>
                    }
                    {...overlayProps}
                >
                    <StyledMiniBasketContent>
                        <StyledMiniBasketItems>
                            <MiniBasketItem {...lastAddedItem} />
                        </StyledMiniBasketItems>
                        <StyledMiniBasketShipping>
                            <FreeShippingProgress />
                            <Text variant="body">
                                {translate('basket.subTotal', {
                                    value: data?.displayTotalPriceInclTax || '...',
                                })}
                            </Text>
                        </StyledMiniBasketShipping>
                        <StyledMiniBasketActions>
                            {staticLinks?.basketPage?.url && (
                                <RouterLink href={staticLinks.basketPage.url}>
                                    <Button as="a">
                                        {translate('minibasket.goTobasketButton', {
                                            quantity: totalQuantity || '0',
                                        })}
                                    </Button>
                                </RouterLink>
                            )}
                            <Button variant="secondary" onClick={closeMiniBasket}>
                                {translate('minibasket.continueButton')}
                            </Button>
                        </StyledMiniBasketActions>
                    </StyledMiniBasketContent>
                </Overlay>
            </StyledMiniBasketContainer>
        </>
    );
});
