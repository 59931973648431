import { StyledResetButton } from '$shared/components';
import styled from '@emotion/styled';
import { readableColor, readableColorIsBlack } from 'color2k';
import { ifProp } from 'styled-tools';
import { Line } from '~/shared/components/Line';
import { Visibility } from '~/shared/utils/jsx';
import { breakpoints, GeneratedThemeShade } from '~/theme';
import { getDuration, getEasing } from '~/theme/themes/animations/baseAnimations';
import { Text } from '$shared/components/Text';

type StyledCrossLineProps = {
    cross?: boolean;
};

export const StyledIconBadge = styled.span<{ backgroundColor?: string }>(
    ({ theme, backgroundColor = theme.colors.light50 }) => ({
        marginLeft: '0.5em',
        fontSize: theme.fontSizes['sm'],
        width: 22,
        height: 24,
        backgroundColor: backgroundColor,
        color: readableColor(backgroundColor),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    })
);

export const StyledMainNavigation = styled.header<
    { isSearchPage?: boolean } & Pick<GeneratedThemeShade, 'backgroundColor' | 'textShade'>
>(({ theme: { text, traits, colors, zIndices }, backgroundColor, textShade, isSearchPage }) => ({
    position: traits.headerIsSticky ? 'sticky' : 'relative',
    backgroundColor: !isSearchPage ? backgroundColor : colors.dark,
    color: !isSearchPage ? text.color[textShade] : colors.light,
    zIndex: zIndices['header'],
    display: 'flex',
    top: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: !isSearchPage ? `1px solid ${colors.light40}` : 'none',
    [breakpoints.md]: {
        color: text.color[textShade],
        backgroundColor: backgroundColor,
        flexWrap: 'nowrap',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 0,
        borderBottom: `1px solid ${colors.light40}`,
    },
    '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        inset: '100% 0 auto 0',
        height: 1,
        background: readableColorIsBlack(traits.background.n21mainMenu)
            ? colors.light30
            : 'transparent',
    },
}));

export const StyledSection = styled.div({
    display: 'grid',
    height: '100%',
    gridAutoFlow: 'column',
});

export const StyledIconsWrapperMobile = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,

    [breakpoints.md]: {
        display: 'none',
    },
});

export const StyledIconsWrapperDesktop = styled.div(({ theme }) => ({
    display: 'none',

    [breakpoints.md]: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        marginRight: `calc(${theme.spaces[3]} * -1)`,
    },
}));

export const StyledIcons = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,

    [breakpoints.md]: {
        marginRight: `calc(${theme.spaces[3]} * -1)`,
    },
}));

export const StyledIcon = styled(StyledResetButton)(({ theme }) => ({
    height: '100%',
    padding: theme.spaces[2],
    outlineOffset: -1,
    fontSize: theme.fontSizes['2xl'],

    // '&:active': {
    //     backgroundColor: readableColorIsBlack(theme.traits.background.n21mainMenu)
    //         ? theme.colors.light30
    //         : theme.colors.dark70,
    // },

    [breakpoints.md]: {
        height: 'auto',
    },
}));

export const StyledLeftArrow = styled(StyledIcon)(({ theme }) => ({
    fontSize: theme.fontSizes['md'],
}));

export const StyledBackArrow = styled(StyledIcon)(({ theme }) => ({
    marginLeft: `-${theme.spaces[2]}`,
    fontSize: theme.fontSizes['md'],
    display: 'flex',
    gap: theme.spaces[2],
    textTransform: 'uppercase',
}));

export const StyledContent = styled.div<{ isSearchPage?: boolean }>(
    ({ theme: { spaces, colors, gridConfig }, isSearchPage }) => ({
        display: 'flex',
        gap: spaces[4],
        padding: `${spaces['10px']}`,
        borderRight: !isSearchPage ? `1px solid ${colors.light40}` : 'none',
        [breakpoints.md]: {
            width: '100%',
            padding: `0 ${gridConfig.gutter}`,
            borderBottom: `1px solid ${colors.light40}`,
            borderRight: 'none',
            height: spaces['50px'],
        },
    })
);

export const StyledMainContent = styled.div<{ isSearchPage?: boolean }>(
    ({ theme, isSearchPage }) => ({
        display: 'flex',
        padding: `0 ${theme.spaces['10px']} 0 0`,
        borderLeft: !isSearchPage ? `1px solid ${theme.colors.light40}` : '',
        marginLeft: 'auto',

        [breakpoints.md]: {
            width: '100%',
            padding: `0 ${theme.gridConfig.gutter}`,
            borderLeft: 'none',
        },
    })
);

export const StyledMobileMegaMenuWrapper = styled.div({
    marginTop: '50px',
    [breakpoints.md]: {
        display: 'none',
    },
});

export const StyledDesktopNav = styled(StyledSection)({
    display: 'none',

    [breakpoints.md]: {
        display: 'grid',
        gridAutoFlow: 'column',
        justifyItems: 'start',
        flex: 1,
    },
});

export const StyledClubOverlaysMobile = styled.div({
    [breakpoints.md]: {
        display: 'none',
    },
});

export const StyledClubOverlaysDesktop = styled.div({
    display: 'none',
    [breakpoints.md]: {
        display: 'block',
    },
});

export const StyledLogoContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    flex: '0 1 auto',
    width: 'auto',
    [breakpoints.md]: {
        width: 'auto',
    },
}));

export const StyledLogoWrapperMobile = styled(Visibility.HiddenFrom)();

export const StyledLogoWrapperDesktop = styled(Visibility.VisibleFrom)();

export const StyledMobileMenuHeader = styled.h1<{ isSearchPage?: boolean }>(
    ({ theme }) => ({
        fontSize: theme.fontSizes.md,
        flex: '1 1 auto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: theme.spaces[3],
    }),
    ifProp('isSearchPage', {
        marginLeft: 0,
    })
);

export const StyledMetaMenu = styled.ul(({ theme }) => ({
    listStyle: 'none',
    display: 'none',
    marginLeft: 'auto',
    alignItems: 'center',
    gap: theme.spaces[4],
    [breakpoints.md]: {
        display: 'flex',
    },
}));

export const StyledMetaButton = styled(StyledIcon)<{ active?: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        height: '100%',
        backgroundColor: 'transparent',
        [breakpoints.md]: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: `0 ${theme.spaces[2]}`,
        },
        '& svg': {
            width: '28px',
            height: 'auto',
        },
        '&:hover svg': {
            opacity: 0.8,
        },
    }),
    ifProp('active', ({ theme }) => ({
        backgroundColor: readableColorIsBlack(theme.traits.background.n21mainMenu)
            ? theme.colors.light30
            : theme.colors.dark70,
    }))
);

export const StyledSearchText = styled(Text)<{ isSmall?: boolean }>(
    ({ theme }) => ({
        paddingLeft: theme.spaces['10px'],
    }),
    ifProp('isSmall', ({ theme }) => ({
        fontSize: theme.fontSizes.sm,
    }))
);

export const StyledMobileMenuButton = styled(StyledIcon)(({ theme }) => ({
    marginRight: `calc(${theme.spaces[2]} * -1)`,
    [breakpoints.md]: {
        display: 'none',
    },
}));

export const StyledLineContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: theme.spaces['20px'],
    width: theme.spaces['20px'],
}));

export const StyledCrossLine = styled(Line)<StyledCrossLineProps>(
    {
        transitionProperty: 'transform',
        transitionDuration: getDuration('moderate01'),
        transitionTimingFunction: getEasing('exit', 'expressive'),
    },
    ifProp('cross', {
        transform: 'rotate(45deg) translate3d(4px, 4px, 0px)',
        transitionTimingFunction: getEasing('entrance', 'expressive'),

        '&:last-child': {
            transform: 'rotate(-45deg) translate3d(3px, -3px, 0px)',
        },
    })
);

export const StyledSearchContainer = styled(Visibility.VisibleFrom)(({ theme }) => ({
    flex: 1,
    minHeight: 40, // fix cls, that would occur if logo height is small
    borderLeft: `1px solid ${theme.colors.light40}`,
    paddingLeft: theme.spaces['10px'],
    marginLeft: theme.spaces[7],
}));

export const StyledMobileSearchContainer = styled(Visibility.HiddenFrom)(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
}));

// Because header is not sticky, the navigation indicator is placed below header, and it is then sticky
export const StyledN21NavigationIndicatorWrapper = styled.div(({ theme }) => ({
    position: 'sticky',
    zIndex: theme.zIndices['header'] + 1,
    top: 0,
    flex: '1 0 100%',
    height: 0,
}));
