import { ComponentProps, memo, useId } from 'react';
import { useTranslation } from '~/shared/utils';
import { VisuallyHidden } from '../VisuallyHidden';
import {
    StyledProgressCustomBar,
    StyledProgressCustomValue,
    StyledProgressWrapper,
} from './styled';

export type ProgressProps = {
    max?: number;
    fraction?: number;
} & ComponentProps<typeof StyledProgressWrapper>;

export const Progress = memo(({ max = 100, fraction = 0, className }: ProgressProps) => {
    const { translate } = useTranslation();
    const id = useId();
    const value = max * fraction;

    return (
        <StyledProgressWrapper className={className}>
            <VisuallyHidden>
                <label htmlFor={id}>{translate('form.label.progress')}</label>
                <progress id={id} max={max} value={value}>
                    {value}
                </progress>
            </VisuallyHidden>
            {/* Set the styling inline, to avoid multiple css classes when passed as prop */}
            <StyledProgressCustomBar aria-hidden>
                <StyledProgressCustomValue
                    style={{ transform: `scaleX(clamp(0,${fraction},1))` }}
                />
            </StyledProgressCustomBar>
        </StyledProgressWrapper>
    );
});
