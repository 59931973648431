import React, { memo } from 'react';
import { StyledMiniBasketItem } from '../styled';
import { LineItemViewModel } from '~/lib/data-contract';
import { Text, Image, PriceGroup } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { Skeleton } from '~/shared/components/Skeleton';

type Props = LineItemViewModel;

export const MiniBasketItem = memo((props: Props) => {
    const { name, imageUrl, color = '', size = '', quantity, priceDetails } = props;

    const { translate } = useTranslation();
    const separator = color && size ? ' - ' : '';

    return (
        <StyledMiniBasketItem>
            {Object.keys(props).length ? (
                <>
                    {imageUrl ? (
                        <Image
                            src={imageUrl}
                            height={105}
                            width={86}
                            sizes="86px"
                            layout="responsive"
                            objectFit="contain"
                        />
                    ) : (
                        <Skeleton height={75} width={86} />
                    )}
                    <div>
                        <Text children={name} />
                        <Text variant="caption" children={`${color}${separator}${size}`} />
                        <Text
                            variant="caption"
                            children={translate('minibasket.lineQuantity', {
                                quantity: (quantity || 1)?.toString(),
                            })}
                        />
                        <PriceGroup {...priceDetails} variant="bodySm" />
                    </div>
                </>
            ) : (
                <>
                    <Skeleton height={75} width={86} shimmer />
                    <Skeleton style={{ width: '1fr', height: '100%' }} shimmer />
                </>
            )}
        </StyledMiniBasketItem>
    );
});
