import React, { memo } from 'react';
import { UspSimple } from '~/lib/data-contract';
import { Image, RouterLink } from '~/shared/components';
import { TextProps, TextShade } from '../Text';
import { StyledImageWrapper, StyledLink, StyledUsp, StyledUspText } from './styled';

export type Props = {
    uspItem?: UspSimple;
    shade?: TextShade;
    textDecoration?: string;
} & Pick<TextProps, 'variant'>;

export const Usp = memo(({ uspItem, shade, textDecoration, variant = 'body' }: Props) => {
    if (!uspItem) {
        return <></>;
    }
    const { link, text, image } = uspItem;

    const child = (
        <StyledUsp tabIndex={link?.url ? undefined : 0}>
            {image?.src && (
                <StyledImageWrapper>
                    <Image
                        src={image.src}
                        alt={image.alt}
                        layout="fill"
                        sizes="24px"
                        objectFit="contain"
                        mimeType="image/svg+xml"
                        skeletonShade="none"
                        showSpinner={false}
                        invert={shade === 'paragraphInverted' || shade === 'headlineInverted'}
                    />
                </StyledImageWrapper>
            )}
            <StyledUspText
                as="span"
                variant={variant}
                shade={shade}
                style={{ color: shade ? undefined : 'currentColor' }}
            >
                {link?.text || text}
            </StyledUspText>
        </StyledUsp>
    );

    return link?.url ? (
        <RouterLink href={link.url} prefetch={false}>
            <StyledLink title={link.title} target={link.target} textDecoration={textDecoration}>
                {child}
            </StyledLink>
        </RouterLink>
    ) : (
        child
    );
});
